// 解码字典 i18n 不支持的字符需要解码
export const decodeDict = {
  $1: "@"
}

export const useDecodeI18nStr = (str: string) => {
  for (const key in decodeDict) {
    str = str.replaceAll(key, decodeDict[key])
  }
  return str
}

export const useTransTag = () => {
  const useATag = (content: string, data: any) => {
    const filterData = data.ref
    const attribute = Object.keys(filterData)
      .map((v) => {
        if (v === "url") {
          return `href=${filterData[v]}`
        }
        return `${v}=${filterData[v]}`
      })
      .join(" ")
    return `<a class="t-c-link-a" ${attribute} target="_blank" >${content}</a>`
  }

  const useSpanTag = (text: string) => {
    return `<span class="t-c-span">${text}</span>`
  }

  const useH2Tag = (text: string) => {
    return `<h2 class="t-c-h2 t-c-strong">${text}</h2>`
  }

  const useDivTag = (text: string) => {
    return `<div class="t-c-div">${text}</div>`
  }

  const useUlTag = (text: string) => {
    return `<ul class="t-c-ul">${text}</ul>`
  }

  const useLiTag = (text: string) => {
    return `<li>${text}</li>`
  }

  const usePtag = (text: string) => {
    return `<p class="t-c-para">${text}</p>`
  }

  const useStrongTitleTag = (text: string) => {
    return `<p class="t-c-strong"><span>${text}</span></p>`
  }

  const useStrongTextTag = (text: string) => {
    return `<span class="t-c-strong_t">${text}</span>`
  }

  const useLinkTag = (text: string, href = "javascript:void", extraClass = "") => {
    return `<a href="${href}" class="t-c-link-a ${extraClass}">${text}</a>`
  }

  const useListTag = (liArr: string[]) => {
    return `
        <ul class="t-c-ul">
           ${liArr
             .map((item) => {
               return `<li>${item}</li>`
             })
             .join("")}
        </ul>
      `
  }

  const useListEgTag = (text: string) => {
    return `<div class="t-c-ul-eg">${text}</div>`
  }
  return {
    useSpanTag,
    useATag,
    useH2Tag,
    useDivTag,
    useUlTag,
    useLiTag,
    usePtag,
    useStrongTitleTag,
    useStrongTextTag,
    useLinkTag,
    useListTag,
    useListEgTag
  }
}
